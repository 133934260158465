import * as React from "react"
import { DateTime } from 'luxon';
import { htmlDirectives } from '../../utils';
import { Link } from 'gatsby';

const directive = require('remark-directive')
var remark2rehype = require('remark-rehype')
var format = require('rehype-format')
var stringify = require('rehype-stringify')
var parse = require('remark-parse')
var unified = require('unified')

export const Post = ({node}) => {
    const post = unified()
      .use(parse)
      .use(directive)
      .use(htmlDirectives)
      .use(remark2rehype)
      .use(format)
      .use(stringify)
      .processSync(node.excerpt.excerpt)
  
    return (
      <div>
          <div className="blog-post-container">
            <div className="blog-post">
              <article className="prose dark:prose-light pb-4">
                <h2><Link to={node.path} style={{textDecoration: "none"}}>{node.title}</Link></h2>
                <div>Posted on {DateTime.fromISO(node.createdDate).toFormat('MMMM d y')}</div>
                <div dangerouslySetInnerHTML={{__html: post}}></div>
                <div><Link to={node.path}>Read more &gt;</Link></div>
              </article>
            </div>
          </div>
      </div>
    )
  }