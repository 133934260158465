import React from "react"
import { graphql } from "gatsby"

import Sidebar from '../components/sidebar';

import {Post} from '../components/post';
import { PaginationBar } from "../components/paginationBar";
import { Helmet } from "react-helmet"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  classes,
  pageContext
}) {
    const pageTitle = pageContext.postType.charAt(0).toUpperCase() + pageContext.postType.slice(1) + 's'
    return (
        <Sidebar>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
            </Helmet>
            <div className="prose dark:prose-light max-w-none ">
                <h1>{pageTitle}</h1>
                {
                    data.allContentfulPost.edges.map(x => <Post node={x.node}/>)
                }
                <PaginationBar pageCount={data.allContentfulPost.pageInfo.pageCount} root={`${pageContext.postType}s`} currentPage={data.allContentfulPost.pageInfo.currentPage}/>
            </div>
        </Sidebar>  
    )
}

export const pageQuery = graphql`
query listPosts($skip: Int!, $postType: String){
    allContentfulPost(
        sort: {order:DESC, fields: [createdDate]}
        filter:{type: {eq:$postType}}
        limit: 6
        skip: $skip
    ){
        edges{
            node{
                title
                createdDate
                path
                excerpt{
                excerpt
                }
            }
        },
        pageInfo {
            perPage,
            hasNextPage,
            hasPreviousPage,
            totalCount,
            pageCount,
            currentPage
        }
    }  
}
`