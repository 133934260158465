import { Link } from "gatsby"
import * as React from "react"

export const PaginationBar = ({pageCount, currentPage, root}) => { 
    return (
      <div className="space-x-3">
          {
              Array.from({length: pageCount}).map((_, i) => (
                <div className={`pb-4 inline-block ${currentPage === i + 1 ? "font-bold": ""}`}>
                    {
                        currentPage === i + 1 ? (
                            <span>{i + 1}</span>
                        ) : (
                            <Link  to={i === 0 ? `/${root}` : `/${root}/${i + 1}`}>{i + 1}</Link>
                        )
                    }
                    
                </div>
              ))
          }
      </div>
    )
  }